export function addJoinWidgets(element = document) {
    element.querySelectorAll('.join-widget-integration').forEach((el) => {
        const storyName = el.dataset.storyId
        const scriptTarget = document.createElement('script')
        scriptTarget.src = `https://lydia.my.join-stories.com/widgets/${storyName}/index.js`
        scriptTarget.dataset.joinWidgetId = `join-widget-${storyName}`
        scriptTarget.dataset.joinWidgetAlias = storyName
        scriptTarget.setAttribute('type', 'text/javascript')
        el.appendChild(scriptTarget)
        el.addEventListener('click', () => {
            window.history.pushState(
                {
                    page: window.history.state.page,
                    type: window.history.state.type,
                },
                '',
                window.history.state.page + `?join-widget=join-widget-${storyName}`,
            )
        })
    })
}

if (window.acf) {
    window.acf.addAction('render_block_preview/type=hero-section', (e) => {
        addJoinWidgets(e[0])
    })
    window.acf.addAction('render_block_preview/type=feature-block', (e) => {
        addJoinWidgets(e[0])
    })
} else {
    addJoinWidgets()
}
