// import blocks
import './dropdown'
import './searchbar'
import './hero'
import './widget'

// generate separate js files for each calculator
if (document.querySelector('#budget-50-30-20')) import('./50-30-20')
if (document.querySelector('#budget-calculator')) import('./duo-calculator')
if (document.querySelector('#budget-planner')) import('./budget-planner')
if (document.querySelector('#calculator-remunerated')) import('./calculator-remunerated')
if (document.querySelector('#referral-button')) import('./referral')
