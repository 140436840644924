export async function search(text, lang) {
    let searchResults = []
    text = remove_accents(text)
    await fetch(
        window.location.origin +
            '/wp-json/helpdocs/search/' +
            lang +
            '/' +
            encodeURIComponent(text.trim()),
        {
            mode: 'same-origin',
            method: 'GET',
        },
    )
        .then((response) => response.json())
        .then((data) => {
            searchResults = data.articles
        })
    return searchResults
}

function remove_accents(strAccents) {
    strAccents = strAccents.split('')
    let strAccentsOut = new Array()
    const strAccentsLen = strAccents.length
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëÇçðÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeCcdDIIIIiiiiUUUUuuuuNnSsYyyZz'
    for (let y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
        } else {
            strAccentsOut[y] = strAccents[y]
        }
    }
    strAccentsOut = strAccentsOut.join('')

    return strAccentsOut
}
