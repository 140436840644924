export function initFaq(element = document) {
    element.querySelectorAll('.accordeon-toggler')?.forEach((el) => {
        el.addEventListener('click', (e) => toggleFaq(e))
    })
}

export function removeFaq(element = document) {
    element.querySelectorAll('.accordeon-toggler')?.forEach((el) => {
        el.removeEventListener('click', (e) => toggleFaq(e))
    })
}

function toggleFaq(e) {
    const content = e.currentTarget.parentElement.querySelector('.accordeon-content')
    const cross = e.currentTarget.querySelector('.accordeon-state')
    const chevron = e.currentTarget.querySelector('.chevron')
    if (cross) {
        cross.classList.toggle('after:scale-x-0')
    } else if (chevron) {
        chevron.classList.toggle('-rotate-180')
    }
    if (content) {
        content.classList.toggle('grid-rows-1fr')
    }
}

if (window.acf) {
    window.acf.addAction('render_block_preview/type=faq-section', (e) => {
        initFaq(e[0])
    })
    window.acf.addAction('render_block_preview/type=landing-faq', (e) => {
        initFaq(e[0])
    })
    window.acf.addAction('render_block_preview/type=comparison-section', (e) => {
        initFaq(e[0])
    })
} else {
    initFaq()
}
