import { throttle } from '../helpers/throttle'

const scroll = {
    current: 0,
    previous: 0,
    direction: 1,
}
const joinBlock = document.querySelector('.join-block')

const toggleClass = throttle(() => {
    joinBlock.classList.toggle('hide', scroll.direction > 0)
}, 10)

if (joinBlock) {
    document.addEventListener('scroll', () => {
        scroll.previous = scroll.current
        scroll.current = window.scrollY
        scroll.direction = scroll.current > scroll.previous ? 1 : -1
        toggleClass()
    })
}
