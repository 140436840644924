const menuToggler = document.querySelectorAll('.menu-toggler')
const navWrapper = document.querySelector('#nav-wrapper')
const menuItems = document.querySelectorAll('.menu-anim li.block')
const activeItem = document.querySelector('.menu-anim .active')
const highlight = document.querySelector('.content-highlight')
let timeout = null

function toggleNav() {
    navWrapper.classList.toggle('translate-x-full')
    document.body.classList.toggle('overflow-hidden')
}

menuToggler?.forEach((element) => {
    element.addEventListener('click', () => toggleNav())
})

if (highlight) {
    if (activeItem) {
        highlight.style.top = `${activeItem.offsetTop}px`
        highlight.style.opacity = '1'
    }

    menuItems?.forEach((element) => {
        element.addEventListener('mouseover', () => {
            highlight.classList.add('transition-all')
            if (timeout) {
                clearTimeout(timeout)
            }
            highlight.style.opacity = '1'
            activeItem?.classList.remove('bg-neutral-z1')
            highlight.style.top = `${element.offsetTop}px`
        })
    })

    window.addEventListener('resize', () => {
        if (activeItem) {
            highlight.style.top = `${activeItem.offsetTop}px`
        }
    })

    document.querySelector('.menu-anim')?.addEventListener('mouseleave', () => {
        timeout = setTimeout(() => {
            if (activeItem) {
                highlight.style.top = `${activeItem.offsetTop}px`
            } else {
                highlight.style.opacity = '0'
            }
        }, 300)
    })
}
