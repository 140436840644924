import { init } from 'prefetch'

if (!window.acf) {
    init({
        containers: ['body'],
        exclusions: [],
        hoverDelay: 1,
        enableTouch: true,
        waitForMousedown: false,
        callback: undefined,
    })
}
