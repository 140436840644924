import { CountUp } from 'countup.js'

const card = document.querySelector('.card-widget')
if (card) {
    const img = card.querySelector('.card-widget img')
    card.addEventListener('mousemove', (e) => {
        if (e.target !== img) {
            return
        }
        const boundingRef = card.getBoundingClientRect()
        const x = e.clientX - boundingRef.left
        const y = e.clientY - boundingRef.top
        const xPercentage = x / boundingRef.width
        const yPercentage = y / boundingRef.height
        const xRotation = (xPercentage - 0.5) * 20
        const yRotation = (0.5 - yPercentage) * 20
        card.style.setProperty('--rotateX', `${xRotation}deg`)
        card.style.setProperty('--rotateY', `${yRotation}deg`)
        card.style.setProperty('--percentX', `${xPercentage * boundingRef.width}px`)
        card.style.setProperty('--percentY', `${yPercentage * boundingRef.height}px`)
    })
}

const counters = document.querySelectorAll('.counter')
if (counters) {
    counters.forEach((counter) => {
        const countAnim = new CountUp(counter, counter.dataset.target, {
            duration: 1.4,
            separator: ' ',
        })
        countAnim.start()
    })
}
