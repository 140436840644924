const counterContainers = document.querySelectorAll('.date-counter')

if (counterContainers?.length > 0) {
    const counterTo = counterContainers[0].dataset.counterTo
    let dateDiff
    let counterInterval = setInterval(() => {
        dateDiff = new Date(counterTo) - new Date()
        if (dateDiff < 0) {
            clearInterval(counterInterval)
            counterContainers.forEach((element) => {
                element.innerHTML = 'The event has ended'
            })
        } else {
            const days = Math.floor(dateDiff / (1000 * 60 * 60 * 24))
            const hours = Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            const minutes = Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((dateDiff % (1000 * 60)) / 1000)
            const computedHtml =
                `${days}j${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m${seconds.toString().padStart(2, '0')}s`
                    .split('')
                    .map((char) => {
                        return `<span class="counter-char">${char === 'm' ? 'min' : char}</span>`
                    })
                    .join('')
            counterContainers.forEach((element) => {
                element.innerHTML = computedHtml
            })
        }
    }, 1000)
    const init = () => {
        dateDiff = new Date(counterTo) - new Date()
        const days = Math.floor(dateDiff / (1000 * 60 * 60 * 24))
        const hours = Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((dateDiff % (1000 * 60)) / 1000)
        const computedHtml =
            `${days}j${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m${seconds.toString().padStart(2, '0')}s`
                .split('')
                .map((char) => {
                    return `<span class="counter-char">${char === 'm' ? 'min' : char}</span>`
                })
                .join('')
        counterContainers.forEach((element) => {
            element.innerHTML = computedHtml
        })
    }
    init()
}
