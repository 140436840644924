import { initFaq, removeFaq } from '../scripts/dropdown'
import { initHero } from '../scripts/hero'
import { addJoinWidgets } from '../helpers/joinStories'
import { initQR } from '../components/magic_link'
// import { initCalculatorRemunerated } from '../components/calculator-remunerated'

const sidebarContent = document.querySelector('#sidebar-content')
const sidebarOverlay = document.querySelector('#sidebar-overlay')
const lang = document.querySelector('html').getAttribute('lang').slice(0, 2)
let isMobile = window.matchMedia('(max-width: 767px)').matches
let buttons

window.historyInitiated = document.querySelector('.front-template') ? false : true
window.isSidebarOpen = false

export function init(element = document) {
    buttons = element.querySelectorAll('.sidebar-toggler')
    buttons?.forEach((button) => {
        button.addEventListener('click', linkClicked)
    })
}

export function remove(element = document) {
    buttons = element.querySelectorAll('.sidebar-toggler')
    buttons?.forEach((button) => {
        button.removeEventListener('click', linkClicked)
    })
}

function isInSite(url) {
    if (url.includes('http') && !url.includes(window.location.origin)) return false
    return true
}

function linkClicked(e) {
    if (isMobile || !isInSite(e.target.href)) return
    e.preventDefault()
    window.historyInitiated = false
    openSidebar({
        page: e.target.dataset.page,
        type: e.target.dataset.type,
    })
}

async function openSidebar({ page, type = 'pages', ispush = true }) {
    window.isSidebarOpen = true
    if (type !== 'manual') {
        if (ispush) {
            window.history.pushState({ page: page, type: type }, '', page)
        }
        if (page.includes(window.location.origin)) {
            page = `?slug=${page.replace(/\/$/, '').split('/').pop()}`
        }
        sidebarOverlay.classList.add('pointer-events-auto')
        document.body.classList.add('overflow-hidden')
        sidebarContent.parentElement.classList.remove('translate-x-full')
        await fetch(`${window.location.origin}/wp-json/wp/v2/${type}/${page}`)
            .then((response) => response.json())
            .then((html) => {
                if (type === 'posts') {
                    const container = document.createElement('div')
                    container.classList.add(
                        'container',
                        'py-24',
                        'max-w-full',
                        '@4xl/content:max-w-prose',
                        'custom-prose',
                        'blog',
                    )
                    container.innerHTML = `<h1 class="not-prose py-2">${html[0].title.rendered}</h1>${html[0].content.rendered}`
                    sidebarContent.append(container)
                } else if (type === 'lexique') {
                    import('../helpers/lexiqueRenderer').then(({ render }) => {
                        sidebarContent.innerHTML = render(html[0].acf)
                    })
                } else if (html.length === 0) {
                    return
                } else if (html.content) {
                    const container = document.createElement('div')
                    container.classList.add('container', 'py-24', 'custom-prose')
                    container.innerHTML = html.content.rendered
                    sidebarContent.append(container)
                } else {
                    const container = document.createElement('div')
                    container.classList.add('container', 'py-24', 'custom-prose')
                    container.innerHTML = html[0].content.rendered
                    sidebarContent.append(container)
                }
                sidebarContent.scrollTo({
                    top: 0,
                    behavior: 'instant',
                })
                initFaq(sidebarContent)
                initHero(sidebarContent)
                addJoinWidgets(sidebarContent)
                initQR(sidebarContent)
                if (sidebarContent.querySelector('#calculator-remunerated')) {
                    import('../scripts/calculator-remunerated').then(
                        ({ initCalculatorRemunerated }) => {
                            initCalculatorRemunerated(sidebarContent)
                        },
                    )
                }
            })
    } else {
        if (ispush) {
            window.history.pushState(
                { page: page, type: type },
                '',
                `${window.location.origin}${
                    lang === 'fr' ? '/aide/article/' : '/' + lang + '/help/article/'
                }${page}`,
            )
        }
        sidebarOverlay.classList.add('pointer-events-auto')
        document.body.classList.add('overflow-hidden')
        sidebarContent.parentElement.classList.remove('translate-x-full')
        await fetch(`${window.location.origin}/wp-json/helpdocs/page/${lang}/${page}`)
            .then((response) => response.json())
            .then((response) => {
                const container = document.createElement('div')
                container.classList.add(
                    'container',
                    'py-24',
                    'max-w-full',
                    '@4xl/content:max-w-prose',
                    'custom-prose',
                    'blog',
                    'prose-headings:text-primary',
                )
                container.innerHTML = `<h1 class="not-prose py-2 w-fit">${response.article.title}</h1>${response.article.body}`
                sidebarContent.append(container)
                sidebarContent.scrollTo({
                    top: 0,
                    behavior: 'instant',
                })
            })
    }
}

function closeSidebar() {
    window.isSidebarOpen = false
    sidebarOverlay.classList.remove('pointer-events-auto')
    document.body.classList.remove('overflow-hidden')
    sidebarContent.parentElement.classList.add('translate-x-full')
    if (sidebarContent.querySelector('#calculator-remunerated')) {
        import('../scripts/calculator-remunerated').then(({ removeCalculatorRemunerated }) => {
            removeCalculatorRemunerated()
        })
    }
    setTimeout(() => {
        sidebarContent.innerHTML = ''
    }, 300)
    removeFaq(sidebarContent)
}

sidebarContent?.addEventListener('click', (e) => {
    e.stopPropagation()
})

sidebarOverlay?.addEventListener('click', () => {
    window.history.back()
    closeSidebar()
})

window.addEventListener('resize', () => {
    isMobile = window.matchMedia('(max-width: 767px)').matches
})

window.addEventListener('pushstate', () => {
    document.querySelectorAll('.join-feed-anchor').forEach((el) => {
        el.remove()
    })
})

window.addEventListener('popstate', (e) => {
    if (window.historyInitiated) {
        window.location.reload()
    } else if (!window.isSidebarOpen) {
        openSidebar({
            page: e.state.page,
            type: e.state.type,
            ispush: false,
        })
    } else {
        const urlParams = new URLSearchParams(window.location.search)
        const joinWidget = urlParams.get('join-widget')
        if (joinWidget) {
            window.wasWidget = true
            window.history.back()
        } else if (!window.wasWidget) {
            closeSidebar()
        }
    }
})

init()
