import autocomplete from 'autocompleter'
import { search } from '../components/search'
import { init as sidebarInit } from '../components/sidebar'
const searchResultsDomFixed = document.querySelector('.search-results-fixed')

function initSearch(element = document) {
    const searchForm = element.querySelector('#form-search')
    const searchFormInput = element.querySelector('#form-search input[name="search"]')
    if (searchForm) {
        const lang = document.documentElement.getAttribute('lang').slice(0, 2)
        searchForm.addEventListener('submit', (e) => {
            e.preventDefault()
        })

        let searchResults = []
        const searchResultsDom = element.querySelector('.search-results')
        const searchResultsLoading = element.querySelector('.search-loading')
        const isDescription =
            searchResultsDom?.dataset.description === 'true' ||
            searchResultsDomFixed?.dataset.description === 'true'
        const limit = searchResultsDom?.dataset.limit || searchResultsDomFixed?.dataset.limit || 20

        setTimeout(() => {
            autocomplete({
                input: searchFormInput,
                container: searchResultsDom,
                minLength: 3,
                debounceWaitMs: 230,
                fetch: async (text, update) => {
                    searchResults = await search(text, lang)
                    update(searchResults)
                    searchResultsLoading.classList.add('hidden')
                    if (!searchResults.length) {
                        searchForm.classList.add('animate-shaking')
                    }
                },
                keyup: (e) => {
                    e.fetch()
                    '' !== e.event.target.value && searchResultsLoading.classList.remove('hidden')
                    if (searchResultsDomFixed) {
                        searchResultsDomFixed.innerHTML = ''
                    }
                    searchForm.classList.remove('animate-shaking')
                },
                render: (item, current, index) => {
                    if (index === 0 && searchResultsDomFixed) {
                        searchResultsDomFixed.innerHTML = ''
                    }
                    if (index === searchResults.length - 1) {
                        setTimeout(() => {
                            if (searchResultsDom) {
                                sidebarInit(searchResultsDom)
                            } else if (searchResultsDomFixed) {
                                sidebarInit(searchResultsDomFixed)
                            }
                        }, 0)
                    }
                    if (!limit || searchResults.indexOf(item) < limit) {
                        const itemElement = document.createElement('a')
                        itemElement.dataset.page = item.article_id
                        itemElement.dataset.type = 'manual'
                        itemElement.href =
                            `${lang === 'fr' ? '/aide/article/' : '/' + lang + '/help/article/'}` +
                            item.article_id
                        itemElement.classList.add(
                            'sidebar-toggler',
                            'text-black',
                            'cursor-pointer',
                            !isDescription ? 'hover:underline' : 'btn',
                        )
                        itemElement.innerHTML = `<span class="pointer-events-none ${
                            isDescription ? 'block heading-4 mb-2 pt-4' : ''
                        }">${item.title}</span>`
                        if (isDescription && item.description) {
                            const description = document.createElement('div')
                            description.classList.add(
                                'text-tertiary',
                                'text-body',
                                'pointer-events-none',
                            )
                            description.textContent = item.description
                            itemElement.appendChild(description)
                        }
                        if (isDescription) {
                            fixedRender(itemElement)
                        } else {
                            return itemElement
                        }
                    }
                },
                className: searchResultsDom?.dataset.class || searchResultsDomFixed?.dataset.class,
                customize: (input, inputRect, container) => {
                    container.style.width = '100%'
                    container.style.top = '100%'
                    container.style.left = 0
                },
            })
        })
    }
}

function fixedRender(itemElement) {
    searchResultsDomFixed?.appendChild(itemElement)
}

if (window.acf) {
    window.acf.addAction('render_block_preview/type=search-block', (e) => {
        initSearch(e[0])
    })
} else {
    initSearch()
}
