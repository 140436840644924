import { toDataURL } from 'qrcode'

export function initQR(element = document) {
    element.querySelectorAll('[data-qr-link]')?.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault()
            if (button.classList.contains('magiclink')) {
                smartRedirect(
                    navigator.userAgent.toLowerCase(),
                    button.dataset.qrTitle,
                    button.dataset.qrLink,
                )
            } else if (
                navigator.userAgent.toLowerCase().indexOf('iphone') != -1 ||
                navigator.userAgent.toLowerCase().indexOf('android') != -1
            ) {
                window.location.replace(button.dataset.qrLink)
            } else {
                openPopup(button.dataset.qrTitle, button.dataset.qrLink)
            }
        })
    })

    document.querySelector('.popup-close')?.addEventListener('click', () => {
        document.querySelector('.popup').classList.remove('active')
    })

    function smartRedirect(agent, title, content) {
        if (agent.indexOf('iphone') != -1) {
            window.location.replace('https://apps.apple.com/us/app/lydia/id575913704')
            return
        } else if (agent.indexOf('android') != -1) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.lydia')
            return
        } else {
            openPopup(title, content)
        }
    }

    function openPopup(title, content) {
        window['popup-title'].innerText = title
        toDataURL(content, { errorCorrectionLevel: 'L' }).then((url) => {
            window['popup-content'].src = url
            window['popup-content'].alt = title
            document.querySelector('.popup').classList.add('active')
        })
    }
}

initQR()
