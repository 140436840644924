export function initHero(element = document) {
    element.querySelectorAll('.hero .lazy')?.forEach((el) => {
        const img = new Image()
        img.src = el.dataset.src
        img.addEventListener('load', (e) => lazyLoadHero(e, el))
    })
    element.querySelectorAll('.qr_toggler')?.forEach((el, i) => {
        const target = element.querySelectorAll('.qr_target')[i] ?? null
        target?.addEventListener('click', (el) => {
            el.target.querySelector('div').classList.remove('active')
            document.body.classList.remove('overflow-hidden')
        })
        el.addEventListener('click', () => initButton(target))
    })
}

export function removeHero(element = document) {
    element.querySelectorAll('.hero .lazy')?.forEach((el) => {
        const img = new Image()
        img.src = el.dataset.src
        img.removeEventListener('load', (e) => lazyLoadHero(e, el))
    })
    element.querySelectorAll('.qr_toggler')?.forEach((el, i) => {
        const target = element.querySelectorAll('.qr_target')[i] ?? null
        target?.removeEventListener('click', (el) => {
            el.target.querySelector('div').classList.remove('active')
            document.body.classList.remove('overflow-hidden')
        })
        el.removeEventListener('click', () => initButton(el))
    })
}

function lazyLoadHero(e, el) {
    el.src = e.target.src
    if (el.parentElement.classList.contains('after:backdrop-blur-sm')) {
        el.parentElement.classList.remove('after:backdrop-blur-sm')
    }
}

function initButton(target) {
    target.querySelector('div').classList.add('active')
    document.body.classList.add('overflow-hidden')
}

if (window.acf) {
    window.acf.addAction('render_block_preview/type=hero-section', (e) => {
        initHero(e[0])
    })
    window.acf.addAction('render_block_preview/type=landing-intro', (e) => {
        initHero(e[0])
    })
} else {
    initHero()
}
